import { ClientCard, ProgressCard } from 'components/Cards';
import { Col, Flex, Line, Row, Text } from 'components/Layout';
import { DocumentStatus } from 'types/resources';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from 'components/Buttons/Button';
import { ListItem } from 'components/List';
import { UploadModal } from 'components/Modal';
import { getStepPercentage } from 'utils/math';
import { md } from 'theme/styles/mediaQueries';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import {
  DocumentFull,
  ProcedureFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { generateFileName } from '../../../utils/downloadFile';
import { InlineButton } from '../../../components/Buttons';
import { useHistory } from 'react-router';
import { formatDocumentTitle } from '../../../utils/format';
import ClientFileModal from '../../../components/Modal/ClientFileModal';

const WrapperCard = styled(ClientCard)`
  height: auto;

  ${md(`
    position: sticky;
    top: 102px;
  `)}
`;

interface DocumentListProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const DocumentsList: FC<DocumentListProps> = ({
  procedure,
  refetchProcedure,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { success: toasterSuccess } = useToaster();
  const [documentToUpload, setDocumentToUpload] = useState<DocumentFull | null>(
    null,
  );
  const [selectedDocument, setSelectedDocument] = useState<DocumentFull | null>(
    null,
  );

  const { execute: getDocuments, state: getDocumentsState } = useApi<
    DocumentFull[]
  >(`/procedures/${procedure.id}/documents?is_administrative=true`);

  const documents = getDocumentsState.data?.value.sort((doc1, doc2) => {
    return doc1.status === DocumentStatus.IN_PROGRESS &&
      doc2.status !== DocumentStatus.IN_PROGRESS
      ? -1
      : 1;
  });

  const getDocumentsCb = () =>
    getDocuments({
      query: { sort: 'updated_at', direction: 'desc' },
    });

  const {
    execute: sendForReview,
    state: { loading: sendForReviewLoading },
  } = useApi(`/procedures/${procedure.id}/documents/review`, {
    method: 'POST',
    onSuccess: () => {
      refetchProcedure();
      getDocumentsCb();
      toasterSuccess(t('documents.progress.success'));
    },
  });

  const {
    execute: uploadDocument,
    state: { loading: uploadDocumentLoading },
  } = useApi(`/procedures/${procedure.id}/documents`, { method: 'POST' });

  const onModalClose = () => {
    setDocumentToUpload(null);
  };

  const documentStep = procedure.steps.find(
    (s) => s.step === ProcedureStepType.DOCUMENT,
  );

  const onConfirmUpload = useCallback(
    (blob: Blob) => {
      if (documentToUpload) {
        const filename = generateFileName(
          formatDocumentTitle(documentToUpload),
          procedure.reference.toString(),
        );
        const body = new FormData();
        body.append('file', blob, filename);

        uploadDocument({
          endpoint: `/procedures/${procedure.id}/documents/${documentToUpload.id}/upload`,
          body,
          onSuccess: () => {
            getDocumentsCb();
            onModalClose();
          },
        });
      }
    },
    [documentToUpload],
  );

  const getProgression = useMemo(() => {
    if (!documents) return {};
    const totalDocuments = documents.length;
    const filledDocuments = documents.filter((document) => {
      return document.file_id && document.documents_comments.length === 0;
    }).length;

    const percentage = Math.round(
      getStepPercentage(filledDocuments, totalDocuments),
    );

    return {
      percentage,
      toCompleteDocuments: totalDocuments - filledDocuments,
      complete:
        percentage === 100 &&
        documentStep?.status === ProcedureStepStatus.IN_PROGRESS,
    };
  }, [documents, procedure]);

  useEffect(() => {
    getDocumentsCb();
  }, []);

  return (
    <Row marginTop={{ xs: 'space32' }}>
      <Col xs={12} marginBottom={{ xs: 'space24' }} mobileOnly>
        <InlineButton
          iconName="ArrowLeft"
          iconColor={theme.colors.salmon2}
          backgroundColor={theme.colors.salmon3}
          text={t('back_folder')}
          onClick={() => {
            history.push('/');
          }}
          hoverBackgroundColor={theme.colors.salmon2}
          hoverIconColor={theme.colors.white}
        />
      </Col>
      <Col xs={12} md={4} lg={4} marginBottom={{ xs: 'space24', md: 'none' }}>
        <WrapperCard
          iconName="Document"
          title={t('documents.progress.title')}
          onClick={sendForReview}
          buttonsDirections={{ xs: 'column' }}
          buttons={[
            documentStep?.status !== ProcedureStepStatus.VALIDATED
              ? {
                  content: t('documents.progress.button'),
                  primary: getProgression.complete,
                  fullWidth: true,
                  disabled: !getProgression.complete,
                  $loading: sendForReviewLoading,
                }
              : null,
          ]}
        >
          {documentStep?.status !== ProcedureStepStatus.VALIDATED ? (
            <ProgressCard
              title={t('documents.progress.card_title')}
              description={
                documentStep?.status === ProcedureStepStatus.IN_PROGRESS
                  ? t('documents.progress.card_description')
                  : t('documents.progress.card_description_in_review')
              }
              percentage={getProgression.percentage || 0}
              showDescriptionOnMobile
            />
          ) : (
            <ProgressCard
              title={t('documents.progress.card_title')}
              description={t('documents.progress.card_description_validate')}
              percentage={100}
              showDescriptionOnMobile
            />
          )}
        </WrapperCard>
      </Col>
      <Col xs={12} md={8}>
        <Flex
          direction={{ xs: 'column' }}
          width="100%"
          marginBottom={{ xs: 'space16' }}
        >
          <Flex
            marginBottom={{ xs: 'space16' }}
            justify="between"
            alignItems="center"
          >
            <Text
              fontStyle="heading5"
              weight="bold"
              content={t('documents.your_documents')}
            />
            <Text
              fontStyle="body2"
              weight="medium"
              content={t('documents.documents_to_add', {
                count: getProgression.toCompleteDocuments,
              })}
              color={theme.colors.gray6}
            />
          </Flex>
          <Flex marginBottom={{ xs: 'space24' }}>
            <Line />
          </Flex>
          {documents?.map((document) => {
            const completed = document.file_id;
            const hasComments = document.documents_comments.length > 0;
            const onDocumentClick = () => {
              // if (uploadAble) {
              setDocumentToUpload(document);
              // }
            };
            const buttons: Array<ButtonProps | null> = [
              document.file_id && document.status !== DocumentStatus.VALIDATE
                ? {
                    onClick: () => setSelectedDocument(document),
                    content: t('documents.see'),
                    primary: false,
                    disabled: false,
                  }
                : null,
              document.status !== DocumentStatus.VALIDATE
                ? {
                    onClick: onDocumentClick,
                    content: completed
                      ? t('documents.modify')
                      : t('documents.add'),
                    primary: !completed || hasComments,
                    disabled: document.status !== DocumentStatus.IN_PROGRESS,
                  }
                : null,
            ];

            return (
              <Flex marginBottom={{ xs: 'space16' }} key={document.id}>
                <ListItem
                  title={formatDocumentTitle(document)}
                  iconName="Document"
                  primary={!completed}
                  buttons={buttons}
                  chip={
                    hasComments &&
                    document.status === DocumentStatus.IN_PROGRESS
                      ? t('documents.comment', {
                          count: document.documents_comments.length,
                        })
                      : ''
                  }
                  onChipClick={onDocumentClick}
                  validated={document.status === DocumentStatus.VALIDATE}
                  type={document.document_template_id}
                  hasLink={
                    hasComments ||
                    [
                      DocumentStatus.IN_PROGRESS,
                      DocumentStatus.IN_REVIEW,
                    ].includes(document.status)
                  }
                />
              </Flex>
            );
          })}
          {!!documentToUpload && (
            <UploadModal
              opened={!!documentToUpload}
              onClose={onModalClose}
              onConfirm={onConfirmUpload}
              loading={uploadDocumentLoading}
              template={documentToUpload.document_template_id}
              comments={documentToUpload.documents_comments}
              documentName={formatDocumentTitle(documentToUpload)}
              documentFilename={generateFileName(
                formatDocumentTitle(documentToUpload),
                procedure.reference.toString(),
              )}
            />
          )}
          {!!selectedDocument && (
            <ClientFileModal
              opened={!!selectedDocument}
              onClose={() => setSelectedDocument(null)}
              document={selectedDocument}
              procedure={procedure}
            />
          )}
        </Flex>
      </Col>
    </Row>
  );
};

export default DocumentsList;
